.bottom-nav-wrapper img {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.75rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  padding: 0.125em;
};

label {
  color: #bb0000;
  font-size: medium;
  font-weight: bold;
};

.InlineDiv {
  display: flex;
  align-items: center;

};
.scarlet{
  color: #bb0000;
};

.scarletButton {
  background-color: #BB0000;
  color: #fff;
}
.ui.button {
  background-color: #666666;
  color: #fff;
}

.buckeyeNut{
  height:60px;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
/* CSS reset - comment out if already using a reset
html, body, div, span, p, a, img, ul, li {margin:0;padding:0;border:0;}
#osu_navbar .container {width:960px; margin: 0 auto;}
*/

/* OSU Navbar styles */


.osu-semantic {position: absolute; left: 0; top: -500px; width: 1px; height: 1px; overflow: hidden;}
a.osu-semantic:active, a.osu-semantic:focus {position: absolute; left:0; top:0; overflow:visible; font-family:'proximanova',Arial,sans-serif;}
a#skip:active, a#skip:focus
{position:absolute; top:0; left:25%; width:50%; text-align:center; padding:0.5em 0 1.5em 0; display:block; color:#fff; z-index:999999999999999999; text-decoration:none; background:#666; background:rgba(0,0,0,0.8); border:1px dotted #ccc; border-top:none; border-radius:0 0 6px 6px;}
a#skip:active:hover, a#skip:focus:hover {background:#b00; background:rgba(187,0,0,0.8);}


/*Update background color, but do not remove red bg*/
#osu_navbar {clear:both; margin:0; padding:0; background: #333 url(../../../Common/assets/bg-navbar_red.png) center bottom repeat-x; overflow:hidden;}
#osu_navbar * {font-family:'proximanova','Helvetica Neue', Helvetica, Arial, sans-serif; font-size:13px;line-height:1.5; font-weight:400;}
#osu_navbar .container {margin:0 auto;}
/* University info */
#osu_navbar p {margin:0; padding:0;}
#osu_navbar .univ_info {float:left; padding: 1em 0 ;}
#osu_navbar .univ_links {float:right; clear:none; padding: 1em 0 0; margin-top:-2px;}
#osu_navbar .univ_name a {height:16px; width:80px; display:block; text-indent:-9999px; background: url(../../../Common/assets/osu_name.png) 0 0 no-repeat; margin-left:20px;}



/* Links */
#osu_navbar div.links {float:left;}
#osu_navbar div.links ul {margin:0;}
#osu_navbar div.links ul li {list-style:none; float:left; margin-right:1em;}
#osu_navbar div.links ul li a {color:#fff; text-decoration: none;}
#osu_navbar div.links ul li a:hover {text-decoration: underline;}



/* --------------------------
   Responsive
   --------------------------
   Must include: <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"> in the <head> of the html file
*/

/* Switch Navbar container from fixed 950px centered to full-width */
@media only screen and (max-width: 990px) {
  #osu_navbar .container {width:100%; margin:0 10px; min-width:300px;} /* Make container full width */
  #osu_navbar .MuiContainer-root {width:100%; margin:0 10px; min-width:300px;} /* Make container full width */
  #osu_navbar div.container .univ_name a {margin-left:0;}
  #osu_navbar div.MuiContainer-root .univ_name a {margin-left:0;}
  #osu_navbar div.links ul {margin-right:10px;}
}


/* Replace text links with icons when space runs out at 720px*/
@media only screen and (max-width: 720px) {
    #osu_navbar div.links ul {margin-top:-2px; margin-right:10px;}
    #osu_navbar div.links ul li {list-style:none; float:left; margin-right:.5em;}
    #osu_navbar div.links ul li a {height:23px; width:23px; display:block; overflow:hidden; text-indent:-999px;}
    #osu_navbar div.links ul li a:hover {text-decoration: none;}
    #osu_navbar div.links ul li a.help {background:url(../../../Common/assets/resp-help.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.buckeyelink {background:url(../../../Common/assets/resp-buckeyelink.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.map {background:url(../../../Common/assets/resp-map.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.findpeople {background:url(../../../Common/assets/resp-findpeople.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.webmail {background:url(../../../Common/assets/resp-webmail.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.search {background:url(../../../Common/assets/resp-search.png) 0 0 no-repeat;}

    /* Hide search box */
    #osu_navbar div.search {display:none;}
}


/* Shrink tOSU name when space runs out at 480px */
/*
@media only screen and  (max-width: 480px) {
  #osu_navbar .univ_name a {height:16px; width:85px; display:block; text-indent:-9999px; overflow:hidden; background: url(../../../Common/assets/osu_name.png) -30px 0 no-repeat;}
}
*/

/* Retina icons */
@media only screen and (max-width: 700px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 700px) and (min--moz-device-pixel-ratio: 2),
only screen and (max-width: 700px) and (-o-min-device-pixel-ratio: 2/1),
only screen and (max-width: 700px) and (min-device-pixel-ratio: 2) {
    #osu_navbar .univ_name a {background-image: url(../../../Common/assets/osu_name.png) !important;
    /* scale name background size */
    /* -webkit-background-size: 100px 16px; -moz-background-size: 100px 16px; */ background-size: contain;}
  #osu_navbar div.links ul li a.help {background-image:url(../../../Common/assets/resp-help.png) !important;}
  #osu_navbar div.links ul li a.buckeyelink {background-image:url(../../../Common/assets/resp-buckeyelink.png) !important;}
  #osu_navbar div.links ul li a.map {background-image:url(../../../Common/assets/resp-map.png) !important;}
  #osu_navbar div.links ul li a.findpeople {background-image:url(../../../Common/assets/resp-findpeople.png) !important;}
  #osu_navbar div.links ul li a.webmail {background-image:url(../../../Common/assets/resp-webmail.png) !important;}
  #osu_navbar div.links ul li a.search {background-image:url(../../../Common/assets/resp-search.png) !important;}

  /* scale icon background size */
  #osu_navbar div.links ul li a.help,
  #osu_navbar div.links ul li a.buckeyelink,
  #osu_navbar div.links ul li a.map,
  #osu_navbar div.links ul li a.findpeople,
  #osu_navbar div.links ul li a.webmail,
  #osu_navbar div.links ul li a.search { -webkit-background-size: 23px; -moz-background-size: 23px; background-size: 23px;}

}

/* Full tOSU name for iPhone 4 horiz */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 480px) {
    /* #osu_navbar .univ_name a {width:220px !important;} */
  }

/* Shorten tOSU name for iPhone 4 vert */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 480px) and (orientation: portrait) {
    /* #osu_navbar .univ_name a {width:95px !important;} */
  }

/* CSS reset - comment out if already using a reset
html, body, div, span, p, a, img, ul, li {margin:0;padding:0;border:0;}
#osu_navbar .container {width:960px; margin: 0 auto;}
*/

/* OSU Navbar styles */


.osu-semantic {position: absolute; left: 0; top: -500px; width: 1px; height: 1px; overflow: hidden;}
a.osu-semantic:active, a.osu-semantic:focus {position: absolute; left:0; top:0; overflow:visible; font-family:'proximanova',Arial,sans-serif;}
a#skip:active, a#skip:focus
{position:absolute; top:0; left:25%; width:50%; text-align:center; padding:0.5em 0 1.5em 0; display:block; color:#fff; z-index:999999999999999999; text-decoration:none; background:#666; background:rgba(0,0,0,0.8); border:1px dotted #ccc; border-top:none; border-radius:0 0 6px 6px;}
a#skip:active:hover, a#skip:focus:hover {background:#b00; background:rgba(187,0,0,0.8);}


/*Update background color, but do not remove red bg*/
#osu_navbar {clear:both; margin:0; padding:0; background: #333 url(../../../Common/assets/bg-navbar_red.png) center bottom repeat-x; overflow:hidden;}
#osu_navbar * {font-family:'proximanova','Helvetica Neue', Helvetica, Arial, sans-serif; font-size:13px;line-height:1.5; font-weight:400;}
/* University info */
#osu_navbar p {margin:0; padding:0;}
#osu_navbar .univ_info {float:left; padding: 1em 0 ;}
#osu_navbar .univ_links {float:right; clear:none; padding: 1em 0 0; margin-top:-2px;}
#osu_navbar .univ_name a {height:16px; width:80px; display:block; text-indent:-9999px; background: url(../../../Common/assets/osu_name.png) 0 0 no-repeat; margin-left:20px;}



/* Links */
#osu_navbar div.links {float:left;}
#osu_navbar div.links ul {margin:0;}
#osu_navbar div.links ul li {list-style:none; float:left; margin-right:1em;}
#osu_navbar div.links ul li a {color:#fff; text-decoration: none;}
#osu_navbar div.links ul li a:hover {text-decoration: underline;}



/* --------------------------
   Responsive
   --------------------------
   Must include: <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"> in the <head> of the html file
*/

/* Switch Navbar container from fixed 950px centered to full-width */
@media only screen and (max-width: 990px) {
  #osu_navbar .container {width:100%; margin:0 10px; min-width:300px;} /* Make container full width */
  #osu_navbar div.container .univ_name a {margin-left:0;}
  #osu_navbar div.links ul {margin-right:10px;}
}


/* Replace text links with icons when space runs out at 720px*/
@media only screen and (max-width: 720px) {
    #osu_navbar div.links ul {margin-top:-2px; margin-right:10px;}
    #osu_navbar div.links ul li {list-style:none; float:left; margin-right:.5em;}
    #osu_navbar div.links ul li a {height:23px; width:23px; display:block; overflow:hidden; text-indent:-999px;}
    #osu_navbar div.links ul li a:hover {text-decoration: none;}
    #osu_navbar div.links ul li a.help {background:url(../../../Common/assets/resp-help.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.buckeyelink {background:url(../../../Common/assets/resp-buckeyelink.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.map {background:url(../../../Common/assets/resp-map.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.findpeople {background:url(../../../Common/assets/resp-findpeople.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.webmail {background:url(../../../Common/assets/resp-webmail.png) 0 0 no-repeat;}
    #osu_navbar div.links ul li a.search {background:url(../../../Common/assets/resp-search.png) 0 0 no-repeat;}

    /* Hide search box */
    #osu_navbar div.search {display:none;}
}


/* Shrink tOSU name when space runs out at 480px */
/*
@media only screen and  (max-width: 480px) {
  #osu_navbar .univ_name a {height:16px; width:85px; display:block; text-indent:-9999px; overflow:hidden; background: url(../../../Common/assets/osu_name.png) -30px 0 no-repeat;}
}
*/

/* Retina icons */
@media only screen and (max-width: 700px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 700px) and (min--moz-device-pixel-ratio: 2),
only screen and (max-width: 700px) and (-o-min-device-pixel-ratio: 2/1),
only screen and (max-width: 700px) and (min-device-pixel-ratio: 2) {
    #osu_navbar .univ_name a {background-image: url(../../../Common/assets/osu_name.png) !important;
    /* scale name background size */
    /* -webkit-background-size: 100px 16px; -moz-background-size: 100px 16px; */ background-size: contain;}
  #osu_navbar div.links ul li a.help {background-image:url(../../../Common/assets/resp-help.png) !important;}
  #osu_navbar div.links ul li a.buckeyelink {background-image:url(../../../Common/assets/resp-buckeyelink.png) !important;}
  #osu_navbar div.links ul li a.map {background-image:url(../../../Common/assets/resp-map.png) !important;}
  #osu_navbar div.links ul li a.findpeople {background-image:url(../../../Common/assets/resp-findpeople.png) !important;}
  #osu_navbar div.links ul li a.webmail {background-image:url(../../../Common/assets/resp-webmail.png) !important;}
  #osu_navbar div.links ul li a.search {background-image:url(../../../Common/assets/resp-search.png) !important;}

  /* scale icon background size */
  #osu_navbar div.links ul li a.help,
  #osu_navbar div.links ul li a.buckeyelink,
  #osu_navbar div.links ul li a.map,
  #osu_navbar div.links ul li a.findpeople,
  #osu_navbar div.links ul li a.webmail,
  #osu_navbar div.links ul li a.search { -webkit-background-size: 23px; -moz-background-size: 23px; background-size: 23px;}

}

/* Full tOSU name for iPhone 4 horiz */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 480px) {
    /* #osu_navbar .univ_name a {width:220px !important;} */
  }

/* Shorten tOSU name for iPhone 4 vert */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 480px) and (orientation: portrait) {
    /* #osu_navbar .univ_name a {width:95px !important;} */
  }
