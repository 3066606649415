label { 
    color: #bb0000 !important;
    font-size: medium!important;
    font-weight: bold !important;
}
.default.text{
    color: #000000 !important;
}

.InlineDiv {
    display: flex;
    align-items: center;

};
.scarlet{
    color: #bb0000;
};


.ui.button {
    background-color: #666666;
    color: #fff;
}

.buckeyeNut{
    width:20px;
    height:20px;
}