html {
    --elevation0: #121212;
    --elevation1: #1e1e1e;
    --elevation2:#232323;
    --elevation3: #252525;
    --elevation4: #272727;
    --elevation5: #2c2c2c;
    --elevation6: #2f2f2f;
    --elevation7: #333333;
    --elevation8: #353535;
    --elevation9: #383838;
    --elevation10: #3a3a3a;
    --elevation11: #3d3d3d;
    --elevation12: #3f3f3f;
    --elevation13: #414141;
    --elevation14: #444444;
    --elevation15: #464646;
    --elevation16: #494949;
    --elevation17: #4b4b4b;
    --elevation18: #4d4d4d;
    --elevation19: #4f4f4f;
    --elevation20: #525252;
    --elevation21: #545454;
    --elevation22: #575757;
    --elevation23: #5a5a5a;
    --elevation24: #5b5b5b;
}